import React, { useState } from 'react';
import navData from '../../assets/nav.json';
import { getAuthToken } from "../Reuse/Token";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [amount, setAmount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // Add isSubmitting state

  const supportPesapal = async (amount, phoneNumber) => {
    try {
      const authToken = await getAuthToken();
      setIsSubmitting(true); // Set submitting to true when API call starts

      const response = await fetch('https://apis.kikimodev.com/api/pesapal/submit-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ amount, phone_number: phoneNumber }), // Pass amount and phone number
      });

      const data = await response.json();
      if (data.status === '200') {
        setRedirectUrl(data.redirect_url);
        setIsModalOpen(true); // Open modal if API call is successful
        // Clear amount and phoneNumber after successful submission
        setAmount('');
        setPhoneNumber('');
      } else {
        alert('Error with payment submission.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Something went wrong!');
    } finally {
      setIsSubmitting(false); // Reset submitting state after the API call completes
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setRedirectUrl(''); // Clear URL when modal closes
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!amount || !phoneNumber) {
      alert('Please enter both amount and phone number.');
      return;
    }
    supportPesapal(amount, phoneNumber); // Submit with amount and phone number
  };

  return (
    <>
      <footer className="bg-white text-gray-700 py-12">
        <div className="max-w-screen-xl px-4 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
          <nav className="flex flex-wrap justify-center -mx-5 -my-2">
            {navData.map((navItem, index) => (
              <div className="px-5 py-2" key={index}>
                <a href={navItem.link} className="text-base leading-6 hover:text-gray-300">
                  {navItem.name}
                </a>
              </div>
            ))}
          </nav>
          <div className="flex justify-center mt-8 space-x-6">
            <a href="#" className="text-red-700 hover:text-red-800">
              {/* Social Media Icons */}
            </a>
            <a href="https://github.com/mmuthigani" className="text-red-700 hover:text-red-800">
              {/* GitHub Icon */}
            </a>
          </div>
          <div className="flex justify-center mt-6 text-base leading-6">
            <p>
              <span className="text-red-700 font-bold">Kikimo Dev™</span> © {new Date().getFullYear()}. All rights reserved.
            </p>
          </div>
        </div>
      </footer>

      {/* Pesapal Button */}
      <div className="flex items-end justify-end fixed bottom-0 right-0 mb-4 mr-4 z-10">
        <div onClick={() => setIsModalOpen(true)}>
          <div
            title="Buy me a Coffee"
            className="block w-full h-12 rounded-full transition-all shadow hover:shadow-lg transform hover:scale-110 hover:rotate-12"
          >
            <img
              className="object-cover object-center w-full h-full rounded-full"
              src="https://img.buymeacoffee.com/button-api/?text=Buy%20me%20a%20coffee&emoji=&slug=tonyricher&button_colour=FF0000&font_colour=ffffff&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00"
              alt="Buy me a coffee"
            />
          </div>
        </div>
      </div>

      {/* Modal for Pesapal Payment */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg w-full sm:w-1/2 sm:max-w-2xl mx-4">
            <button onClick={closeModal} className="absolute top-0 right-0 p-2">
              <span className="text-xl">X</span>
            </button>

            {/* Show form only if redirectUrl is not set */}
            {!redirectUrl ? (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
                  <input
                    type="number"
                    id="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="w-full p-2 mt-1 border border-gray-300 rounded-md"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Phone Number</label>
                  <input
                    type="text"
                    id="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="w-full p-2 mt-1 border border-gray-300 rounded-md"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full p-2 bg-red-600 text-white rounded-md"
                  disabled={isSubmitting} // Disable button when submitting
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'} {/* Change button text */}
                </button>
              </form>
            ) : (
              // If redirectUrl is set, show the iframe
              <iframe
                src={redirectUrl}
                width="100%"
                height="500px"
                frameBorder="0"
                title="Pesapal Payment"
              ></iframe>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
