import React, { useState } from "react";
import Title from "../Reuse/Title";
import BlurBackground from "../Reuse/BlurBackground";
import { API_URL } from "../Reuse/Common";
import { getAuthToken } from "../Reuse/Token";

const AddReview = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    message: "",
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      // Fetch the authentication token
      const authToken = await getAuthToken();
  
      // Make the POST request with the token included in headers
      const response = await fetch(`${API_URL}/contacts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Include token in header
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setResponseMessage("Review submitted successfully!");
        setFormData({
          name: "",
          email: "",
          phone_number: "",
          message: "",
        });
      } else {
        setResponseMessage(data.message || "Error submitting review. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      setResponseMessage("Network error. Please try again.");
    }
  
    setLoading(false);
  };
  

  return (
    <>
      <Title title="Get a quote" description="Get Custom Pricing" />
      {loading ? (
        <div className="mx-auto pb-0">
          <div className="p-4 bg-white border border-primary rounded-md">
            <div className="flex">
              <div className="mr-4 bg-gray-200 border border-gray-200 h-16 w-16 rounded animate-pulse"></div>
              <div className="space-y-1 flex flex-col w-full">
                <div className="flex w-full items-center">
                  <div className="bg-gray-200 border border-gray-200 w-60 h-5 animate-pulse"></div>
                  <div className="ml-4 bg-ternary w-12 h-5 animate-pulse"></div>
                </div>
                <div className="bg-gray-200 border border-gray-200 w-36 h-5 animate-pulse"></div>
                <div className="bg-gray-200 border border-gray-200 w-full h-44 animate-pulse"></div>
              </div>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <div className="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
                <span className="bg-tertiary h-1 w-1 rounded animate-pulse"></span>
                <div className="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
              </div>
              <div className="bg-gray-200 border border-gray-200 w-16 h-5 animate-pulse"></div>
            </div>
          </div>
        </div>
      ) : (
        <BlurBackground
          layout={
            <div class="mx-auto flex max-w-md flex-col rounded-lg lg:max-w-screen-xl lg:flex-row">
              <div class="max-w-2xl px-4 lg:pr-24">
                <p class="mb-2 text-red-600">Tailored Solutions for Your Business</p>
                <h3 class="mb-5 text-3xl font-semibold">Request Custom Pricing</h3>
                <p class="mb-16 text-lg text-gray-600">
                Need a solution that's as unique as your business? We offer personalized pricing plans to meet your specific needs. Let’s create something remarkable together.
                </p>
                <div class="mb-5 flex font-medium">
                  <div class="mr-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-7 w-7 text-red-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m-6 3.75l3 3m0 0l3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75"
                      />
                    </svg>
                  </div>
                  <div class="">
                    <p class="mb-2">SEO Optimization</p>
                    <span class="font-normal text-gray-600">
                    Boost your online visibility with our expert SEO services. Increase traffic, rank higher on search engines, and drive more leads to your business.
                    </span>
                  </div>
                </div>
                <div class="mb-5 flex font-medium">
                  <div class="mr-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-7 w-7 text-red-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                      />
                    </svg>
                  </div>
                  <div class="">
                    <p class="mb-2">Monthly Updates</p>
                    <span class="font-normal text-gray-600">
                    Stay ahead of the competition with regular updates. We’ll keep your website up-to-date with the latest features, ensuring top performance and user experience.
                    </span>
                  </div>
                </div>
                <div class="mb-5 flex font-medium">
                  <div class="mr-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-7 w-7 text-red-500"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                      />
                    </svg>
                  </div>
                  <div class="">
                    <p class="mb-2">Launch Your Website in Under 2 Weeks</p>
                    <span class="font-normal text-gray-600">
                    Get your professional website up and running fast. We deliver fully customized, high-quality websites in less than two weeks, so you can focus on growing your business.
                    </span>
                  </div>
                </div>
              </div>
              <div class="border border-gray-100 shadow-gray-500/20 mt-8 mb-8 max-w-md bg-white shadow-sm sm:rounded-lg sm:shadow-lg lg:mt-0">
                <div class="relative border-b border-gray-300 p-4 py-8 sm:px-8">
                  <h3 class="mb-1 inline-block text-3xl font-medium">
                    <span class="mr-4">Get a quote!</span>
                    <span class="inline-block rounded-md bg-red-100 px-2 py-1 text-sm text-red-700 sm:inline">
                      Quick Response
                    </span>
                  </h3>
                  <p class="text-gray-600">Contact us for custom use cases</p>
                </div>
                <div className="p-4 sm:p-8">
                  <form onSubmit={handleSubmit}>
                    <input
                      id="name"
                      type="text"
                      className="mt-4 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-red-500 focus:outline-none hover:border-red-500"
                      placeholder="Enter your name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />

                      <input
                      id="phone_number"
                      type="text"
                      className="mt-4 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-red-500 focus:outline-none hover:border-red-500"
                      placeholder="Enter your phone number"
                      value={formData.phone_number}
                      onChange={handleChange}
                      required
                    />
                    <input
                      id="email"
                      type="email"
                      className="peer mt-8 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-red-500 focus:outline-none hover:border-red-500"
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <div className="peer-invalid:block mt-1 hidden text-left text-xs text-rose-600">
                      Email format Incorrect. Please complete the email
                    </div>
                    <label className="mt-5 mb-2 inline-block max-w-full">
                      Tell us about your company
                    </label>
                    <textarea
                      id="message"
                      className="mb-8 w-full resize-y overflow-auto rounded-lg border border-gray-300 px-4 py-2 shadow-sm focus:border-red-500 focus:outline-none hover:border-red-500"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                    <button
                      type="submit"
                      className="w-full rounded-lg border border-red-700 bg-red-700 p-3 text-center font-medium text-white outline-none transition focus:ring hover:border-red-700 hover:bg-red-600 hover:text-white"
                      disabled={loading}
                    >
                      {loading ? 'Sending...' : 'Send'}
                    </button>
                  </form>

                  {responseMessage && (
                    <p className="mt-4 text-center text-lg text-red-700">
                      {responseMessage}
                    </p>
                  )}
                </div>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};

export default AddReview;
