import React, { useState, useEffect } from "react";
import user from "../../assets/user.png";
import Title from "../Reuse/Title";
import BlurBackground from "../Reuse/BlurBackground";
import Loader from "../Reuse/Loader";

import { API_URL } from "../Reuse/Common";
import { getAuthToken } from "../Reuse/Token";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const authToken = await getAuthToken();
        const response = await fetch(`${API_URL}/reviews`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        setReviews(data.reviews);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  return (
    <>
      <Title
        title="Don't Just Take Our Word For It"
        description="See what others are saying"
      />

      <BlurBackground
        layout={
          <div>
            {loading ? (
              <Loader />
            ) : (
              <section className="p-10 w-full mx-auto bg-transparent bg-no-repeat">
                <div className="container mx-auto">
                  <div className="items-center justify-center w-full mt-0 mb-0 lg:flex">
                    {reviews.map((review, index) => (
                      <div
                        key={index}
                        className={`flex flex-col items-center justify-center w-full h-auto px-0 mx-0 mb-12 border-r border-transparent lg:w-1/3 lg:mb-0 lg:px-8 lg:mx-8 ${
                          index === reviews.length - 1
                            ? ""
                            : "lg:border-red-600"
                        }`}
                      >
                        <div className="flex items-center justify-center">
                          <div className="mr-4 overflow-hidden bg-gray-200 rounded-full shadow">
                            <img
                              className="h-12 w-12 rounded-full object-cover"
                              src={user}
                              alt=""
                            />
                          </div>
                          <div className="flex flex-col items-center justify-center">
                            <h4 className="text-sm font-semibold">
                              {review.name}
                            </h4>
                            <p className="text-sm text-gray-900">
                              {review.role}
                            </p>
                          </div>
                        </div>
                        <blockquote className="mt-4 text-center text-sm text-gray-900">
                          "{review.review}"
                        </blockquote>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            )}
          </div>
        }
      />
    </>
  );
};

export default Reviews;
