import React, { useState, useEffect } from "react";
import Title from "../Reuse/Title";
import BlurBackground from "../Reuse/BlurBackground";
import Modal from "../Reuse/Modal";
import Loader from "../Reuse/Loader";
import WorkCard from "../Cards/WorkCard";
import { API_URL } from "../Reuse/Common";
import { getAuthToken } from "../Reuse/Token";

const RecentWork = () => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [imgData, setImgData] = useState("");
  const [currentFeatures, setFeatures] = useState("");
  const [works, setWorks] = useState([]);
  const [showLoadingModal, setShowLoadingModal] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWork = async () => {
      try {
        const authToken = await getAuthToken();
        const response = await fetch(`${API_URL}/recent-portfolios`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        if (response.ok && data.portfolios) {
          setWorks(data.portfolios);
        } else {
          setError("Failed to fetch works");
        }
      } catch (err) {
        setError("An error occurred while fetching the data");
      } finally {
        setShowLoadingModal(false);
      }
    };

    fetchWork();
  }, []);

  const handleViewImageModal = (imageSrc,features) => {
    setImgData(imageSrc);
    setFeatures(features);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImgData("");
  };

  return (
    <>
      <Title title="Creativity at Its Best" description="Our Recent Designs" />

      <BlurBackground
        layout={
          <div>
            {showLoadingModal && <Loader />}
            {error && <p className="text-red-500">{error}</p>}
            <div className="flex flex-wrap items-center overflow-x-auto overflow-y-hidden py-2 justify-center text-gray-800">
              {works.map((data) => (
                <WorkCard
                  key={data.id}
                  data={data}
                  handleViewImageModal={handleViewImageModal}
                />
              ))}
            </div>
          </div>
        }
      />
{showImageModal && (
  <Modal
    layout={
      <>
        <img className="rounded w-full" src={imgData} alt="Modal" />
        <div className="mt-4">
          {currentFeatures && (
            <>
              <p>
                <strong>Features:</strong>
              </p>
              {JSON.parse(currentFeatures).map((feature, index) => (
                <div key={index} className="flex items-center mb-3">
                  <div className="bg-red-300 mr-3 p-1 rounded-full">
                    <svg className="h-3 w-3 text-red-800 fill-current" viewBox="0 0 20 20" version="1.1">
                      <polygon points="0 11 2 9 7 14 18 3 20 5 7 18"></polygon>
                    </svg>
                  </div>
                  <p className="text-xs">{feature}</p>
                </div>
              ))}
            </>
          )}
        </div>
      </>
    }
    handleCloseModal={handleCloseModal}
  />
)}

    </>
  );
};

export default RecentWork;
