import React, { useState, useEffect } from "react";
import BlurBackground from "../Reuse/BlurBackground";
import Modal from "../Reuse/Modal";
import { API_URL } from "../Reuse/Common";
import Loader from "../Reuse/Loader";
import WorkCard from "../Cards/WorkCard";
import Pagination from "../utils/Pagination";
import { getAuthToken } from "../Reuse/Token";
import logo from '../../assets/logo.png';

const WorkDisplay = () => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [imgData, setImgData] = useState("");
  const [currentFeatures, setFeatures] = useState("");
  const [works, setWorks] = useState([]);
  const [showLoadingModal, setShowLoadingModal] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [activeTab, setActiveTab] = useState("GraphicsDesign");

  const fetchWork = async (category, page = 1) => {
    try {
      const authToken = await getAuthToken();
      const response = await fetch(
        `${API_URL}/all-portfolios?category=${category}&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok && data.portfolios) {
        setWorks(data.portfolios);
        setCurrentPage(data.pagination.current_page);
        setTotalPages(data.pagination.total_pages);
      } else {
        setError("Failed to fetch works");
      }
    } catch (err) {
      setError("An error occurred while fetching the data");
    } finally {
      setShowLoadingModal(false);
    }
  };

  useEffect(() => {
    let tabTitle = "";
    switch (activeTab) {
      case "GraphicsDesign":
        tabTitle = "Graphic Design";
        break;
      case "UIUX":
        tabTitle = "UI/UX Design";
        break;
      case "WebDevelopment":
        tabTitle = "Web Developments";
        break;
      case "AppDevelopment":
        tabTitle = "App Developments";
        break;
      default:
        tabTitle = "Work Display";
    }
    document.title = `${tabTitle} | Kikimo Developers`;
    fetchWork(activeTab); // Fetch work for the selected category
  }, [activeTab]);

  const handleViewImageModal = (imageSrc,features) => {
    setImgData(imageSrc);
    setFeatures(features);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImgData("");
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber !== currentPage) {
      fetchWork(activeTab, pageNumber);
    }
  };

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <div className="tabs flex flex-wrap justify-center mb-5 overflow-x-auto">
        <button
          className={`tab-button px-4 py-2 mx-2 rounded w-32 m-4 ${
            activeTab === "GraphicsDesign"
              ? "bg-red-600 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => setActiveTab("GraphicsDesign")}
        >
          Graphic Design
        </button>
        <button
          className={`tab-button px-4 py-2 mx-2 rounded w-32 m-4 ${
            activeTab === "UIUX"
              ? "bg-red-600 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => setActiveTab("UIUX")}
        >
          UI/UX Design
        </button>
        <button
          className={`tab-button px-4 py-2 mx-2 rounded w-32 m-4 ${
            activeTab === "WebDevelopment"
              ? "bg-red-600 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => setActiveTab("WebDevelopment")}
        >
          Web Development
        </button>
        <button
          className={`tab-button px-4 py-2 mx-2 rounded w-32 m-4 ${
            activeTab === "AppDevelopment"
              ? "bg-red-600 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => setActiveTab("AppDevelopment")}
        >
          App Development
        </button>
      </div>

      <BlurBackground
        layout={
          <div>
            {showLoadingModal && <Loader />}

            {error && <p className="text-red-500">{error}</p>}

            <div className="flex flex-wrap items-center overflow-x-auto overflow-y-hidden py-2 justify-center text-gray-800">
              {works.map((data) => (
                <WorkCard
                  key={data.id}
                  data={data}
                  handleViewImageModal={handleViewImageModal}
                />
              ))}
            </div>

            {/* Pagination Component */}
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageClick}
            />
          </div>
        }
      />

{showImageModal && (
  <Modal
    layout={
      <>
        <div className="relative">
          {/* The image */}
          <img className="rounded w-full" src={imgData} alt="Modal" />

          {/* The centered logo */}
          <div className="absolute inset-0 flex items-center justify-center">
            <img
              src={logo} // Replace with the actual logo path
              alt="Logo"
              className="h-12 opacity-50 px-4" // Adjust width, height, and opacity as needed
            />
          </div>
        </div>

        <div className="mt-4">
          {currentFeatures && (
            <>
              <p>
                <strong>Features:</strong>
              </p>
              {JSON.parse(currentFeatures).map((feature, index) => (
                <div key={index} className="flex items-center mb-3">
                  <div className="bg-red-300 mr-3 p-1 rounded-full">
                    <svg
                      className="h-3 w-3 text-red-800 fill-current"
                      viewBox="0 0 20 20"
                      version="1.1"
                    >
                      <polygon points="0 11 2 9 7 14 18 3 20 5 7 18"></polygon>
                    </svg>
                  </div>
                  <p className="text-xs">{feature}</p>
                </div>
              ))}
            </>
          )}
        </div>
      </>
    }
    handleCloseModal={handleCloseModal}
  />
)}


    </>
  );
};

export default WorkDisplay;
