// Token.js
// import { API_URL } from './Common';
export const getAuthToken = async () => {
    try {
      // Step 1: Get the token from login API
      const loginResponse = await fetch(`https://apis.kikimodev.com/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          credential: 'apiuser',
          password: 'apiuser',
        }),
      });
  
      const loginData = await loginResponse.json();
      const authToken = loginData.authorisation.token;
      
      if (authToken) {
        return authToken;
      } else {
        throw new Error('Token not found');
      }
    } catch (error) {
      console.error('Error fetching token:', error);
      throw error;
    }
  };
  