import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layouts/Navbar';
import Footer from './components/layouts/Footer';
import HomePage from './components/layouts/HomePage';
import Services from './components/layouts/Services';
import Blogs from './components/layouts/Blogs';
import ErrorPage from './components/layouts/ErrorPage';
import SinglePost from './components/layouts/SinglePost';
import WorkDisplay from './components/layouts/WorkDisplay';
import LiveWork from './components/layouts/LiveWork';

import Login from './components/Auth/Login';
import Dashboard from './components/Auth/Dashboard';
import PrivateRoute from './components/Auth/PrivateRoute';

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:slug" element={<SinglePost />} />
        <Route path="/portfolios" element={<WorkDisplay />} />
        <Route path="/login" element={<Login />} />
        <Route path="/live-work" element={<LiveWork />} />
        
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
