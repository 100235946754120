import React, { useState, useEffect } from 'react';
import { API_URL } from '../Reuse/Common';
import BlurBackground from '../Reuse/BlurBackground';
import Loader from '../Reuse/Loader';
import BlogCard from '../Cards/BlogCard';
import Pagination from '../utils/Pagination';
import { getAuthToken } from "../Reuse/Token";

const Blogs = () => {
    const [posts, setPosts] = useState([]);
    const [pagination, setPagination] = useState({});
    const [showLoadingModal, setShowLoadingModal] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const fetchPosts = async () => {
        setShowLoadingModal(true); // Show loader when starting fetch
        try {
            const authToken = await getAuthToken();
            const response = await fetch(`${API_URL}/all-posts?page=1&perPage=10`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                });
            const data = await response.json();

            if (response.ok && data.posts) { // Check response.ok for HTTP status
                setPosts(data.posts);
                setPagination(data);
                setCurrentPage(data.current_page);
                setTotalPages(Math.ceil(data.total / data.per_page)); // Set total pages for pagination
            } else {
                setError('Failed to fetch posts');
            }
        } catch (err) {
            setError('An error occurred while fetching the data');
        } finally {
            setShowLoadingModal(false); // Hide loader after fetch
        }
    };

    useEffect(() => {
        fetchPosts();
        document.title = "Blogs | Kikimo Developers";
    }, []);

    // Function to handle page click
    const handlePageClick = (pageNumber) => {
        if (pageNumber !== currentPage) {
            fetchPosts(pageNumber);
        }
    };


    return (
        <div>
            <BlurBackground
                layout={
                    <div>
                        {showLoadingModal && <Loader />}

                        {error && <p className="text-red-500">{error}</p>}

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {posts.map((data, index) => (
                                <BlogCard key={index} data={data} index={index} />
                            ))}
                        </div>

                        {/* Pagination Component */}
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageClick}
                        />

                    </div>
                }
            />
        </div>
    );
};

export default Blogs;
