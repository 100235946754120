import React from 'react';

const Common = () => {
    const url = process.env.REACT_APP_API_URL; 
    return url; 
};

export default Common;

export const API_URL = 'https://apis.kikimodev.com/api';


